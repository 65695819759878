@tailwind base;
@tailwind components;
@tailwind utilities;

$dark-pink: rgba(215, 131, 127);
$text-color: #381c00;

body {
  color: $text-color;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.article > p {
  margin: 1em 0;
}

.fade-popup {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
}

::selection {
  background: $dark-pink;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-thumb {
  background-color: $dark-pink;
  border-radius: 9px;
  border: 4px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}